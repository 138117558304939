import React, { useCallback, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./dashboard/shared/auth-context";
import "bootstrap/dist/css/bootstrap.min.css";
import { Layout } from "antd";
import "./App.css";

import AdminList from "./dashboard/pages/AdminListComponents/AdminList";
import AdminForm from "./dashboard/pages/AdminListComponents/AdminForm";
import Navbar from "./dashboard/shared/Navbar";

import Login from "./dashboard/Admin-components/Login";

let logoutTimer;
const { Content, Footer } = Layout;

function App() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [adminId, setAdminId] = useState(null);
  const [isPageReloading, setIsPageReloading] = useState(false);

  const login = useCallback((isLoggedIn, adminId) => {
    setIsLoggedIn(isLoggedIn);
    setAdminId(adminId);
    localStorage.setItem(
      "SuperAdminData",
      JSON.stringify({
        isLoggedIn,
        adminId,
        expirationTime: Date.now() + 86400000, // Set expiration time to 1 day from now
      })
    );
    startLogoutTimer(); // Start the logout timer
  }, []);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    setAdminId(null);

    localStorage.removeItem("SuperAdminData");
    clearTimeout(logoutTimer); // Clear the logout timer
    navigate("/login");
  }, [navigate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("SuperAdminData"));
    if (
      storedData &&
      storedData.adminId &&
      storedData.expirationTime > Date.now() // Check if the expiration time is still valid
    ) {
      login(storedData.isLoggedIn, storedData.adminId);
    } else {
      navigate("/login");
    }
  }, [login, navigate]);

  useEffect(() => {
    setIsLoggedIn(auth.isLoggedIn);
    setAdminId(auth.adminId);
  }, [auth.isLoggedIn, auth.adminId]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = () => {
    setIsPageReloading(true);
  };

  useEffect(() => {
    if (isPageReloading) {
      setIsPageReloading(false);
      window.location.reload();
    }
  }, [isPageReloading]);

  const startLogoutTimer = () => {
    const storedData = JSON.parse(localStorage.getItem("SuperAdminData"));
    const expirationTime = storedData ? storedData.expirationTime : null;
    const remainingTime = expirationTime - Date.now();
    logoutTimer = setTimeout(logout, remainingTime); // Logout after the remaining time
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        adminId,
        login,
        logout,
      }}
    >
      <div className="main">
        {isLoggedIn ? (
          <Layout style={{ height: "100%" }}>
            <Navbar />
            <Layout>
              <Content style={{ margin: "24px 16px 0" }}>
                <div
                  className="site-layout-background"
                  style={{ padding: 24, minHeight: 600 }}
                >
                  <Routes>
                    <Route
                      path="/login"
                      exact
                      element={<Navigate to="/adminlist" />}
                    />
                    <Route
                      path="*"
                      exact
                      element={<Navigate to="/adminlist" />}
                    />

                    <Route path={`/adminlist`} exact element={<AdminList />} />
                    <Route path="/addadmin" exact element={<AdminForm />} />

                    <Route
                      path="*"
                      exact
                      element={<Navigate to="/adminlist" />}
                    />
                  </Routes>
                </div>
              </Content>
              <Footer style={{ textAlign: "center" }}>
                © 2023 TariSoft. All rights reserved.
              </Footer>
            </Layout>
          </Layout>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </div>
    </AuthContext.Provider>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
