import React, { Fragment, useContext } from "react";
import { Layout, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../shared/auth-context";

const { Sider } = Layout;

function Navbar() {
  const Navigate = useNavigate();
  const auth = useContext(AuthContext);

  const logoutHandler = () => {
    auth.logout();
    Navigate("/login");
  };
  return (
    <Fragment>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div >
          <img
            className="img-fluid"
            src={process.env.PUBLIC_URL + "/img/default.png"}
            alt="Icon"
            style={{ width: "200px", padding:"20px" }}
          />
        </div>
        <Menu theme="dark" mode="inline" defaultOpenKeys={["admins"]} defaultSelectedKeys={["1"]}>
          <Menu.SubMenu
            key="admins"
            title="Admins"

          >
            <Menu.Item style={{ color: "white" }} key="1" >
              <Link to={`/adminlist`}>Adminlist</Link>
            </Menu.Item>
            <Menu.Item key="addadmin">
              <Link to={`/addadmin`}>Add New Admin</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.Item key="2">
            <Link to onClick={logoutHandler}>
              LogOut
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    </Fragment>
  );
}

export default Navbar;
