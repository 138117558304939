import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Adminform.css";

import { Modal, Collapse, Switch } from "antd";
import axios from "axios";
import { Formik } from "formik";

const { Panel } = Collapse;

function AdminForm() {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div>
      <div className="py-5">
        <div className="container">
          <div className="main">
            <Formik
              initialValues={{
                name: "",
                email: "",
                password: "",
                confirmpassword: "",
                userlist: false,
                propertylist: false,
                featuredlist: false,
                verifiedlist: false,
                contactuslist: false,
                propertytypelist: false,
                detaillist: false,
                active: false,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = "Name Required";
                }

                if (!values.email) {
                  errors.email = "Email Required";
                }

                if (!values.password) {
                  errors.password = "Password Required";
                }

                if (!values.confirmpassword) {
                  errors.confirmpassword = "Confirm Password Required";
                } else if (values.password !== values.confirmpassword) {
                  errors.confirmpassword = "Invalid Confirm Password";
                }

                return errors;
              }}
              onSubmit={async (values, actions) => {
                try {
                  const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/auth/admin/signup`,
                    {
                      name: values.name,
                      email: values.email,
                      password: values.password,
                      userlist: false,
                      propertylist: false,
                      featuredlist: false,
                      verifiedlist: false,
                      contactuslist: false,
                      propertytypelist: false,
                      detaillist: false,
                      active: false,
                    }
                  );
                  if (response.data.message) {
                    Modal.warning({
                      title: response.data.message,
                    });
                  } else {
                    if (response.status === 200) {
                      Modal.success({
                        title: "Admin added Successfully",
                      });
                      navigate("/adminlist");
                    }
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <div>
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="edit_property">
                      <div
                        className="form-field"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <label>Enter Name</label>
                        <div style={{ display: "flex", width: "100%" }}>
                          <input
                            type="text"
                            className="form-control"
                            required
                            id="name"
                            name="name"
                            placeholder="Enter Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                        </div>
                        <p style={{ color: "red" }}>
                          {errors.name && touched.name && errors.name}
                        </p>
                      </div>
                      <div className="form-field">
                        <label>Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Enter Email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        <p style={{ color: "red" }}>
                          {errors.email && touched.email && errors.email}
                        </p>
                      </div>
                      <div className="form-field">
                        <label>Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          name="password"
                          placeholder="Enter Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                      </div>
                      <div className="form-field">
                        <label>Confirm Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="confirmpassword"
                          name="confirmpassword"
                          placeholder="Enter Confirm Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmpassword}
                        />
                      </div>
                      <div className="Submit">
                        <button
                          className="btn btn-primary py-2 action_submit_buttons"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminForm;
