import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import {DeleteOutlined,FolderOpenOutlined} from "@ant-design/icons";
import "datatables.net-dt/css/jquery.dataTables.css";
import axios from "axios";
import { Modal, Switch, message } from "antd";
const UserList = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [accessModal, setAccessModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  useEffect(() => {
   
    fetchData();
  }, []);
  const fetchData = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/adminlist`);
    console.log(data);
    setUser(data);
  };

  if (user.length === 0 ) {
    return (
      <div
        id="spinner"
        style={{ backdropFilter: "blur(4px)" }}
        class="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          class="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const handleView = (item) => {
    setModalContent(item);
    setAccessModal(true);
  };
  const handleModalClose = () => {
    setAccessModal(false);
  };
  const handleAccess = async (item, fieldName) => {
    const updatedItem = {
      ...item,
      [fieldName]: !item[fieldName], // Toggle the field value
    };
  
    try {
      Modal.confirm({
        title: 'Verify Access',
        content: 'Do you want to give this access to the admin?',
        okButtonProps: { // Add this to style the OK button
          style: { width: "80px" },
        },
        cancelButtonProps: {
          style: { width: "80px" },
        },
        onOk: async () => {
          // Perform the property verification
          try {
            const response = await axios.patch(
              `${process.env.REACT_APP_BASE_URL}/auth/adminupdation/${item.id}`,
              updatedItem
            );
  
            console.log(response);
            if (response.status === 200) {
              Modal.success({
                title: 'Access Submitted Successfully!',
                content: response.data.message,
                onOk: () => {
                  // Update the switch state
                  const updatedItem = { ...item, [fieldName]: !item[fieldName] };
                  // Update the state or perform any necessary actions with the updatedItem
                  setUser((prevUsers) =>
                    prevUsers.map((prevUser) =>
                      prevUser.id === item.id ? updatedItem : prevUser
                    )
                  );
                },
              });
              handleModalClose();
              
            }
          } catch (err) {
            const message =
              err.response?.data?.message ||
              'Something went wrong. Please try again!';
            // setError(message);
            console.log(message, err.response?.status);
          }
        },
      });
    } catch (err) {
      const message =
        err.response?.data?.message ||
        'Something went wrong. Please try again!';
      Error(message);
      console.log(message, err.response?.status);
    }
  
    // Handle verification switch button toggle
    console.log('Verification switch toggled:', updatedItem);
  };
  
  const handleDelete = (item) => {
    Modal.confirm({
      title: "Confirmation",
      content: "Are you sure you want to delete this user?",
      okButtonProps: { // Add this to style the OK button
        style: { width: "80px" },
      },
      cancelButtonProps: {
        style: { width: "80px" },
      },
      onOk: () => {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/auth/admin/${item._id}`)
          .then((res) => {
            console.log("response", res);
            fetchData();
            navigate('/adminlist');
          })
          .catch((error) => {
            console.log("error block called", error);
          });
      },
      onCancel: () => {
        // Handle cancel logic if needed
      },
    });
  };

  if (user.length === 0) {
    return <div>Loading...</div>;
  }

  const data = {
    columns: [
      // {
      //   label: "ID",
      //   field: "id",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },

      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: "150px",
      },
    ],
    rows: user.map((item) => ({
      id: item.id,
      name: item.name,
      email: item.email,

      action: (
        <div className="action-buttons">
          <FolderOpenOutlined
            style={{ color: "blue", fontSize: 24 }}
            onClick={() => handleView(item)}
          />

          <DeleteOutlined
            onClick={() => handleDelete(item)}
            style={{ color: "red", marginLeft: 12, fontSize: 24 }}
          />
        </div>
      ),
    })),
  };

  const options = {
    searchBox: true, // Enable the search box
    searchBoxClass: "form-control", // Set the class for the search box
    searchLabel: "Search", // Set the label for the search box
    searchText: "", // Set the initial search text
    filterText: "Filter", // Set the label for the filter dropdown
    responsive: true, // Enable responsive mode
    responsiveSm: true,
    responsiveMd: true,
    responsiveLg: true,
    responsiveXl: true,
    rowsPerPage: 10, // Set the number of rows per page
    paginationLabel: ["Previous", "Next"], // Set the labels for pagination
  };

  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
        fontSize: "18px",
        whiteSpace: "nowrap",
      }}
    >
      <MDBDataTable
        id="myDataTable"
        data={data}
        options={options}
        searching={true}
        sortable={true}
        striped
        bordered
        noBottomColumns={true}
      />
      <Modal
        visible={accessModal}
        onCancel={handleModalClose}
        footer={null}
        title="View Contact"
      >
        <div>
          <p>ID: {modalContent.id}</p>
          <p>Name: {modalContent.name}</p>
          <p>Email: {modalContent.email}</p>

          <p>
            Userlist:
            <Switch
            style={{marginLeft:"60px", width:"20px"}}
              checked={modalContent.userlist}
              onChange={() => handleAccess(modalContent, "userlist")}
            />
          </p>
          <p>
            Propertylist:
            <Switch
             style={{marginLeft:"34px", width:"20px"}}
              checked={modalContent.propertylist}
              onChange={() => handleAccess(modalContent, "propertylist")}
            />
          </p>
          <p>
            Featuredlist:
            <Switch
             style={{marginLeft:"33px", width:"20px"}}
              checked={modalContent.featuredlist}
              onChange={() => handleAccess(modalContent, "featuredlist")}
            />
          </p>
          <p>
            Verifiedlist:
            <Switch
             style={{marginLeft:"40px", width:"20px"}}
              checked={modalContent.verifiedlist}
              onChange={() => handleAccess(modalContent, "verifiedlist")}
            />
          </p>
          <p>
            Contactuslist:
            <Switch
            style={{marginLeft:"24px", width:"20px"}}
              checked={modalContent.contactuslist}
              onChange={() => handleAccess(modalContent, "contactuslist")}
            />
          </p>
          <p>
            PropertyTypelist:
            <Switch
            style={{marginLeft:"4px", width:"20px"}}
              checked={modalContent.propertytypelist}
              onChange={() => handleAccess(modalContent, "propertytypelist")}
            />
          </p>
          <p>
            DetailTypelist:
            <Switch
            style={{marginLeft:"24px", width:"20px"}}
              checked={modalContent.detailtypelist}
              onChange={() => handleAccess(modalContent, "detailtypelist")}
            />
          </p>
          <p>
            Citylist:
            <Switch
            style={{marginLeft:"24px", width:"20px"}}
              checked={modalContent.citylist}
              onChange={() => handleAccess(modalContent, "citylist")}
            />
          </p>
          <p>
            Active:
            <Switch
            style={{marginLeft:"65px", width:"20px"}}
              checked={modalContent.contactuslist}
              onChange={() => handleAccess(modalContent, "active")}
            />
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default UserList;
